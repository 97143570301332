'use client';
import React, { useEffect, useState } from 'react';

import { LinkButton } from '@/components/ui/link-button';

export const Banner: React.FC = () => {
  const [academiasCounter, setAcademiasCounter] = useState(0);
  const [modalidadesCounter, setModalidadesCounter] = useState(0);

  const academiasLimit = 4500;
  const modalidadesLimit = 250;

  const startCounting = (counter: number, setCounter: React.Dispatch<React.SetStateAction<number>>, limit: number) => {
    const interval = setInterval(() => {
      setCounter((counter) => {
        if (counter < limit) {
          return counter + 1;
        }
        clearInterval(interval);
        return limit;
      });
    }, 130);
  };

  useEffect(() => {
    startCounting(academiasCounter, setAcademiasCounter, academiasLimit);
    startCounting(modalidadesCounter, setModalidadesCounter, modalidadesLimit);
  }, [academiasCounter, modalidadesCounter]);

  const formatNumber = (number: number) => {
    return number.toFixed(0).replace('4', '4,');
  };

  return (
    <section className="bg-primary-800 relative overflow-hidden">
      <div
        data-poster-url="https://cms.totalpass.com/uploads/Imagem_Site_5c20ef57a9.png?updated_at=2024-04-11T22:39:07.257Z"
        data-video-urls="https://cms.totalpass.com/uploads/banner_d1d9d30480.webm?updated_at=2024-04-11T22:38:00.644Z, https://cms.totalpass.com/uploads/banner_0e8636b0bb.mp4?updated_at=2024-04-11T22:35:39.139Z"
        data-autoplay
        data-loop
        data-wf-ignore
        className="absolute z-10 flex min-h-full w-full items-center justify-center"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore
          className="absolute z-10 min-h-full w-auto min-w-full max-w-none object-cover"
        >
          <source
            src="https://cms.totalpass.com/uploads/banner_d1d9d30480.webm?updated_at=2024-04-11T22:38:00.644Z"
            type="video/webm"
          />
          <source
            src="https://cms.totalpass.com/uploads/banner_0e8636b0bb.mp4?updated_at=2024-04-11T22:35:39.139Z"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="container mx-auto px-6 text-white">
        <div className="relative z-10 flex flex-col gap-10 pb-12 pt-64 lg:pb-28 xl:w-1/2">
          <h1 className="text-2xl font-medium lg:text-5xl">
            Los mejores gimnasios, estudios y centros deportivos en un solo beneficio corporativo
          </h1>
          <div className="flex flex-col gap-4">
            <p className="text-base lg:text-lg">Accede a:</p>
            <div className="grid grid-cols-3 gap-4 md:flex md:flex-row">
              <div className="flex flex-col md:w-[40%]">
                <p className="text-[32px] font-bold leading-none lg:text-[40px]">+{formatNumber(academiasCounter)}</p>
                <p className="text-sm lg:text-base">gimnasios</p>
              </div>
              <div className="flex flex-col md:w-[33%]">
                <p className="text-[32px] font-bold leading-none lg:text-[40px]">+{modalidadesCounter}</p>
                <p className="text-sm lg:text-base">actividades</p>
              </div>
              <div className="flex flex-col">
                <p className="text-sm leading-none md:text-[40px] md:font-bold">plataformas de salud mental</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:flex-row">
            <LinkButton className="rounded-full text-base font-normal" href="/mx/para-empresas/" variant="primary">
              Para empresas
            </LinkButton>
            <LinkButton className="rounded-full text-base font-normal" href="/mx/colaboradores/" variant="secondary">
              Para colaboradores
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};
